import React, { useState } from 'react';
import { RefferalBlock } from '../../RefferalBlock';
import { Trans } from 'react-i18next';
import { OPENSALE_END_DATA } from '../../../../../helpers/tiers';

import { OpenSaleCardTimer } from '../../../../../components/Timer/OpenSaleCardTimer';

export const SaleTitle = ({ uplineAddress }) => {
  const [isTimerEnd, setIsTimerEnd] = useState(OPENSALE_END_DATA);
  return (
    <div className="max-w-[57rem] w-full h-full flex flex-col justify-between space-y-[2.4rem] p-[4rem] lg:p-0 lg:max-w-full">
      <div className="flex flex-col space-y-[2.4rem] lg:items-center lg:text-center">
        <div className="flex items-center justify-start space-x-[2rem]">
          <img className="animate-pulse w-[6rem]" src="/logoWithoutText.svg" alt="" />
          <OpenSaleCardTimer time={OPENSALE_END_DATA} isCompleted={isTimerEnd} onComplete={() => setIsTimerEnd(true)} />
        </div>
        <span className="text-[#F0F0F0] text-[6rem] leading-[6.3rem] font-700 space-grotesk lg:text-[4rem] lg:leading-normal">
          $POLLY <br /> Public Sale
        </span>
        <span className="text-[#F0F0F0] opacity-50 text-[1.6rem] font-400 lg:text-[1.4rem]">
          <Trans i18nKey="landing.openSale.leftBar.connectDesc">
            Connect your wallet and buy $POLLY tokens in Polygon
          </Trans>
        </span>
      </div>
      <RefferalBlock type="public" uplineAddress={uplineAddress} />
    </div>
  );
};
