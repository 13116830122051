import React, { useEffect, useState } from 'react';
import { StakingDays } from './StakingDays';
import { StakingInput } from './StakingInput';
import { StakingInfo } from './StakingInfo';
import WhitePlusIcon from '../../../assets/icons/generalIcons/whitePlusIcon.svg';
import PercentIcon from '../../../assets/icons/staking/percentIcon.svg';
import HoldingIcon from '../../../assets/icons/staking/holdingIcon.svg';
import { useStaking } from '../../../helpers/hooks/useStaking';
import { useStakingApprove } from '../../../helpers/hooks/useStakingApprove';
import { useWeb3React } from '@web3-react/core';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { callNotification } from '../../../helpers/notification';
import { useSelector } from 'react-redux';
import { getBalance } from '../../../store/walletSlice/selectors';
import { AnimatedCounter } from '../../../components/AnimatedCounter';

const periodVariants = [604800, 2592000, 7776000, 15552000];

const bonusPercents = [0, 10, 20, 30];

export const StakingForm = ({ getUserDeposits }) => {
  const [inputValue, setInputValue] = useState('');
  const [period, setPeriod] = useState(periodVariants[3]);

  const { account } = useWeb3React();
  const { stake, basicAPR, getBaseApr, getAmountPeriodApr, amountAPR } = useStaking();
  const { isApproved, isLoadingApprove, checkApprove, callApprove } = useStakingApprove();

  const { stPolly: stPollyBalance } = useSelector(getBalance);

  const isDisabledButton = (inputValue === 0 || !inputValue) && isApproved;

  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const {
    onCallTransaction: onCallTransactionApprove,
    transactionInfo: transactionInfoApprove,
    resetTransactionInfo: resetTransactionInfoApprove,
  } = useCallTransaction();

  const totalAPR = amountAPR ? amountAPR : basicAPR;
  const totalBonusAPR = !!(amountAPR - basicAPR) && amountAPR ? amountAPR - basicAPR : 0;
  const currentBonusAPR = bonusPercents[periodVariants.findIndex((item) => item === period)];

  const clickStake = async () => {
    if (isApproved) {
      try {
        const result = await stake(inputValue, period);

        onCallTransaction(result);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result = await callApprove();

        onCallTransactionApprove(result);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (inputValue > 0 && account) {
      getAmountPeriodApr(inputValue, period);
    }
  }, [account, inputValue, period]);

  useEffect(() => {
    if (account) {
      checkApprove();
      getBaseApr();
    }
  }, [account]);

  useEffect(() => {
    if (transactionInfo.isWaiting) {
      callNotification({ type: 'info', message: 'Staking Polly..' });
    }
    if (transactionInfo.isSuccess && account) {
      getUserDeposits();
      callNotification({ type: 'success', message: 'Stake successed' });
      resetTransactionInfo();
    }
  }, [transactionInfo, account]);

  useEffect(() => {
    if (transactionInfoApprove.isSuccess && account) {
      callNotification({ type: 'success', message: 'Approve successed' });
      resetTransactionInfoApprove();
    }
  }, [transactionInfoApprove, account]);

  const infoCard1 = () => {
    return (
      <div className="h-[27rem] p-[4rem] flex flex-col justify-between w-full rounded-[3rem] bg-white-30 border border-solid border-white-50 backdrop-blur-[2.5rem] lg:rounded-[2rem] lg:p-[2rem] lg:h-[16.8rem]">
        <div className="flex items-center justify-between w-full">
          <span className="text-white text-[2.4rem] font-600 leading-none lg:text-[1.6rem]">
            <span className="opacity-40">Stake POLLY,</span> get stPOLLY
          </span>
          <div className="absolute right-[2rem] top-[2rem] flex items-center justify-center bg-white-50 border-[0.1rem] border-white-50 border-solid h-[4.8rem] w-[4.8rem] rounded-[1.6rem] lg:right-[1.3rem] lg:top-[1.3rem] lg:w-[3.2rem] lg:h-[3.2rem] lg:rounded-[1rem]">
            <img className="lg:w-[1.73rem] lg:h-[1.73rem]" src={PercentIcon} />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start space-y-[1.6rem] leading-none">
          <span className="text-[1.6rem] font-500 text-white-400 lg:text-[1.4rem]">Current APY:</span>
          <span className="text-[4.8rem] font-600 text-white lg:text-[3.2rem]">{basicAPR}%</span>
        </div>
      </div>
    );
  };

  const infoCard2 = () => {
    return (
      <div className="h-[27rem] p-[4rem] flex flex-col justify-between w-full rounded-[3rem] bg-white-30 border border-solid border-white-50 backdrop-blur-[2.5rem] lg:rounded-[2rem] lg:p-[2rem] lg:h-[16.8rem]">
        <div className="flex items-center justify-between w-full">
          <span className="text-white text-[2.4rem] font-600 leading-none lg:text-[1.6rem]">My holdings</span>
          <div className="absolute right-[2rem] top-[2rem] flex items-center justify-center bg-white-50 border-[0.1rem] border-white-50 border-solid h-[4.8rem] w-[4.8rem] rounded-[1.6rem] lg:right-[1.3rem] lg:top-[1.3rem] lg:w-[3.2rem] lg:h-[3.2rem] lg:rounded-[1rem]">
            <img className="lg:w-[1.73rem] lg:h-[1.73rem]" src={HoldingIcon} />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start space-y-[1.6rem] leading-none">
          <span className="text-[1.6rem] font-500 text-white-400 lg:text-[1.4rem]">My stPOLLY balances</span>
          <div className="flex items-center justify-start space-x-[0.5rem] text-[4.8rem] font-600 text-white lg:text-[3.2rem]">
            <AnimatedCounter endNum={stPollyBalance || 0} /> <span className="text-white-400">stPOLLY</span>
          </div>
        </div>
      </div>
    );
  };

  const form = () => {
    return (
      <div className="poly-staking-form w-full p-[4rem] flex flex-col items-start justify-start space-y-[3.2rem] rounded-[3rem] border border-solid border-white-50 backdrop-blur-[1.5rem] lg:p-[2rem]">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-[1rem]">
            <img className="w-[3.2rem] h-[3.2rem] lg:w-[2rem] lg:h-[2rem]" src="/images/staking/polyLogo.webp" />
            <span className="text-[2.4rem] font-600 tracking-[-0.48px] text-white lg:text-[1.6rem] lg:tracking-[0.32px]">
              $POLLY Stake
            </span>
          </div>
          <div className="flex items-center space-x-[1rem] lg:space-x-[0.8rem]">
            <span className="text-white-400 font-500 text-[1.6rem] lg:text-[1.2rem]">APY</span>
            <div className="flex space-x-[1rem] text-[2.4rem] font-500 lg:text-[1.6rem] lg:space-x-[0.8rem]">
              <div
                className={`custom-transition flex items-center justify-start text-white ${
                  !!totalBonusAPR ? '!text-[#51F689]' : ''
                }`}
              >
                {totalAPR} %
              </div>
            </div>
          </div>
        </div>
        <StakingInput inputValue={inputValue} setInputValue={setInputValue} />
        <StakingDays period={period} setPeriod={setPeriod} periodVariants={periodVariants} bonusAPR={currentBonusAPR} />
        <StakingInfo />
        <button
          disabled={isDisabledButton}
          onClick={clickStake}
          className="flex items-center justify-center space-x-[0.4rem] h-[4.8rem] w-full bg-[#AF7FFF] rounded-[1.2rem] shadow-[0px_10px_40px_0px_rgba(175,127,255,0.30)] custom-transition disabled:opacity-60 hover:opacity-60"
        >
          {isApproved && <img src={WhitePlusIcon} />}
          <span className="text-[1.6rem] text-white font-500 leading-[2.24rem]">
            {isApproved ? 'Stake' : 'Approve'}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start justify-start space-y-[4.8rem]">
      <div className="flex justify-center space-x-[2rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[2.4rem] ">
        {form()}
        <div className="flex-shrink-0 w-[56rem] flex flex-col items-center justify-center space-y-[2rem] h-full lg:w-full">
          {infoCard1()}
          {infoCard2()}
        </div>
      </div>
    </div>
  );
};
