import React from 'react';

export const StakingInfo = () => {
  const infoList = [
    {
      title: 'You will receive',
      count: '0.0 FRGX',
    },
    {
      title: 'Exchange rate',
      count: '1 FRGX = 0.30 USD',
    },
    {
      title: 'Max transaction cost',
      count: '$7.10',
    },
    {
      title: 'Reward fee',
      count: '2%',
    },
  ];
  return (
    <div className="flex flex-col space-y-[1.6rem] w-full">
      {infoList.map((item, itemIndex) => {
        return (
          <div
            className="flex w-full items-center justify-between text-[1.4rem] font-500 leading-[1.6rem]"
            key={itemIndex}
          >
            <span className="text-white-400">{item?.title}</span>
            <span>{item?.count}</span>
          </div>
        );
      })}
    </div>
  );
};
